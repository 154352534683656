import { createRouter, createWebHistory } from 'vue-router'
import { getOAuthToken, isTokenValid, refreshAuthToken } from '../services/api/axios.js'
import { getContent } from '@/services/cms/util.js'
import { getSystemAvailability } from '@/services/api/systemability/systemability.js'
import LandingPage from '../views/LandingPage.vue'
import InitiateLogin from '../views/InitiateLogin.vue'
import { useSelectedPlan } from '@/stores/plans/selectedPlan.js'
import { useAccessRights } from '@/stores/accessRights/accessRights.js'
import { useClaimsFilters } from '@/stores/claims/claimsFilters'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			redirect: '/login',
			meta: { hideNavigation: true, hideFooter: true, hideChat: true }
		},
		{
			path: '/login',
			name: 'login',
			component: LandingPage,
			meta: { hideNavigation: true, hideFooter: true, hideChat: true }
		},
		{
			path: '/initiate-login',
			name: 'InitiateLogin',
			component: InitiateLogin,
			meta: { hideNavigation: true, hideFooter: true, hideChat: true }
		},
		{
			path: '/register',
			name: 'VerifyMember',
			component: () => import('../views/VerifyMember.vue'),
			meta: { hideNavigation: true, hideFooter: true, hideChat: true }
		},
		{
			path: '/create-account',
			name: 'CreateAccount',
			component: () => import('../views/CreateAccount.vue'),
			meta: { hideNavigation: true, hideFooter: true, hideChat: true }
		},
		{
			path: '/communication-settings',
			name: 'EmailOptions',
			component: () => import('../views/EmailOptions.vue'),
			meta: { hideNavigation: true, hideFooter: true, hideChat: true }
		},
		{
			path: '/complete-registration',
			name: 'RegisterUser',
			component: () => import('../views/CompleteRegistration.vue'),
			meta: { hideNavigation: true, hideFooter: true, hideChat: true }
		},
		{
			path: '/outage-message',
			name: 'outagemsg',
			component: () => import('../views/OutageMsg.vue'),
			meta: { hideNavigation: true, hideFooter: true, hideChat: true }
		},
		{
			path: '/select-plan',
			name: 'SelectPlan',
			component: () => import('../views/SelectPlan.vue'),
			meta: { requiresAuth: true, hideFooter: true, hideChat: true, title: 'Select Plan' }
		},
		{
			path: '/dashboard',
			redirect: '/medical/dashboard',
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/medical/dashboard',
			name: 'MemberDashboard',
			component: () => import('../views/MemberDashboard.vue'),
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/dv-dashboard',
			redirect: '/dental/dashboard',
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/dental/dashboard',
			name: 'DentalDashboard',
			component: () => import('../views/DentalVisionDashboard.vue'),
			alias: ['/dental/dashboard'],
			meta: {
				requiresAuth: true,
				contractTypes: ['dental']
			}
		},
		{
			path: '/vision/dashboard',
			name: 'VisionDashboard',
			component: () => import('../views/DentalVisionDashboard.vue'),
			alias: ['/vision/dashboard'],
			meta: {
				requiresAuth: true,
				contractTypes: ['vision']
			}
		},
		{
			path: '/spending-details',
			name: 'SpendingDetails',
			component: () => import('../views/SpendingDetails.vue'),
			alias: ['/medical/spending-details'],
			meta: {
				requiresAuth: true,
				title: 'Spending Details',
				contractTypes: ['medical'],
				rightsValue: 'deductible'
			}
		},
		{
			path: '/health-reimbursement',
			name: 'HealthReimbursement',
			component: () => import('../views/HealthReimbursement.vue'),
			alias: ['/medical/health-reimbursement'],
			meta: {
				requiresAuth: true,
				title: 'Health Reimbursement Account',
				contractTypes: ['medical'],
				rightsValue: 'healthReimbursementAccount'
			}
		},
		{
			path: '/programs',
			name: 'Programs',
			component: () => import('../views/Programs.vue'),
			alias: ['/medical/programs', '/dental/programs', '/vision/programs'],
			meta: {
				requiresAuth: true,
				title: 'Programs',
				contractTypes: ['medical', 'dental', 'vision'],
				rightsValue: 'programs'
			}
		},
		{
			path: '/programs/:id',
			name: 'ProgramDetails',
			component: () => import('../views/ProgramDetails.vue'),
			alias: ['/medical/programs/:id', '/dental/programs/:id', '/vision/programs/:id'],
			meta: {
				requiresAuth: true,
				pageTitle: true,
				contractTypes: ['medical', 'dental', 'vision'],
				rightsValue: 'programs-id'
			}
		},
		{
			path: '/pharmacy',
			name: 'Pharmacy',
			component: () => import('../views/Pharmacy.vue'),
			alias: ['/medical/pharmacy'],
			meta: {
				requiresAuth: true,
				title: 'Pharmacy',
				contractTypes: ['medical', 'dental', 'vision'],
				rightsValue: 'pharmacyCenter'
			}
		},
		{
			path: '/contact-us',
			name: 'ContactUs',
			component: () => import('../views/ContactUs.vue'),
			alias: ['/medical/contact-us', '/dental/contact-us', '/vision/contact-us'],
			meta: {
				requiresAuth: true,
				title: 'Contact Us',
				contractTypes: ['medical', 'dental', 'vision']
			}
		},
		{
			path: '/medical-claims',
			redirect: '/claims',
			meta: { requiresAuth: true }
		},
		{
			path: '/medical/medical-claims',
			redirect: '/claims',
			meta: { requiresAuth: true }
		},
		{
			path: '/claims',
			name: 'MedicalClaims',
			component: () => import('../views/MedicalClaims.vue'),
			alias: ['/medical/claims'],
			meta: {
				requiresAuth: true,
				title: 'Claims',
				contractTypes: ['medical'],
				rightsValue: 'claims'
			}
		},
		{
			path: '/claim-details/:id',
			name: 'ClaimDetails',
			component: () => import('../views/ClaimDetails.vue'),
			alias: ['/medical/claim-details/:id'],
			meta: {
				requiresAuth: true,
				title: 'Claim Details',
				contractTypes: ['medical'],
				rightsValue: 'claims'
			}
		},
		{
			path: '/payment-center',
			name: 'PaymentCenter',
			component: () => import('../views/PaymentCenter.vue'),
			alias: ['/medical/payment-center', '/dental/payment-center', '/vision/payment-center'],
			meta: {
				requiresAuth: true,
				title: 'Payment Center',
				contractTypes: ['medical', 'dental', 'vision'],
				rightsValue: 'payment'
			}
		},
		{
			path: '/primary-care-provider',
			name: 'PrimaryCareProvider',
			component: () => import('../views/PrimaryCareProvider.vue'),
			alias: ['/medical/primary-care-provider'],
			meta: {
				requiresAuth: true,
				title: 'Primary Care Provider',
				contractTypes: ['medical'],
				rightsValue: 'choosePrimaryCareProvider'
			}
		},
		{
			path: '/coverage-and-copays',
			name: 'CoverageAndCopays',
			component: () => import('../views/CoverageAndCopays.vue'),
			alias: [
				'/medical/coverage-and-copays',
				'/dental/coverage-and-copays',
				'/vision/coverage-and-copays'
			],
			meta: {
				requiresAuth: true,
				title: 'Coverage and Copays',
				contractTypes: ['medical', 'dental', 'vision'],
				rightsValue: 'whosCovered'
			}
		},
		{
			path: '/prior-authorizations',
			name: 'PriorAuthorizations',
			component: () => import('../views/PriorAuthorizations.vue'),
			alias: ['/medical/prior-authorizations'],
			meta: {
				requiresAuth: true,
				title: 'Prior Authorizations',
				contractTypes: ['medical'],
				rightsValue: 'inpatientMedicalAdmissionRequest'
			}
		},
		{
			path: '/find-care',
			name: 'FindCare',
			component: () => import('../views/FindCare.vue'),
			alias: ['/medical/find-care', '/dental/find-care', '/vision/find-care'],
			meta: {
				requiresAuth: true,
				title: 'Find Care',
				contractTypes: ['medical', 'dental', 'vision'],
				rightsValue: 'findCareAndCostOptions'
			}
		},
		{
			path: '/health-records',
			name: 'HealthRecords',
			component: () => import('../views/PersonalHealthRecords.vue'),
			alias: ['/medical/health-records'],
			meta: {
				requiresAuth: true,
				title: 'Health Records',
				contractTypes: ['medical'],
				rightsValue: 'personalHealthRecord'
			}
		},
		{
			path: '/traveling-abroad',
			name: 'CoverageAbroad',
			component: () => import('../views/CoverageAbroad.vue'),
			alias: ['/medical/traveling-abroad'],
			meta: {
				requiresAuth: true,
				title: 'Coverage Abroad',
				contractTypes: ['medical'],
				rightsValue: 'coverageWhenTravelingAbroad'
			}
		},
		{
			path: '/settings',
			name: 'Settings',
			component: () => import('../views/UserSettings.vue'),
			alias: ['/medical/settings', '/dental/settings', '/vision/settings'],
			meta: {
				requiresAuth: true,
				title: 'Settings',
				contractTypes: ['medical', 'dental', 'vision']
			}
		},
		{
			path: '/terms-of-use',
			name: 'TermsOfUse',
			component: () => import('../views/TermsOfUse.vue'),
			alias: ['/medical/terms-of-use', '/dental/terms-of-use', '/vision/terms-of-use'],
			meta: {
				requiresAuth: true,
				title: 'Terms of Use',
				contractTypes: ['medical', 'dental', 'vision']
			}
		},
		{
			path: '/linking-disclaimer',
			name: 'LinkingDisclaimer',
			component: () => import('../views/LinkingDisclaimer.vue'),
			alias: [
				'/medical/linking-disclaimer',
				'/dental/linking-disclaimer',
				'/vision/linking-disclaimer'
			],
			meta: {
				requiresAuth: true,
				title: 'Linking Disclaimer',
				contractTypes: ['medical', 'dental', 'vision']
			}
		},
		{
			path: '/id-card',
			name: 'IdCard',
			component: () => import('../views/MemberIdCard.vue'),
			alias: ['/medical/id-card', '/dental/id-card', '/vision/id-card'],
			meta: {
				requiresAuth: true,
				title: 'Member ID Card',
				contractTypes: ['medical', 'dental', 'vision'],
				rightsValue: 'idCards'
			}
		},
		{
			path: '/documents',
			name: 'DocumentsAndForms',
			component: () => import('../views/DocumentsAndForms.vue'),
			alias: ['/medical/documents', '/dental/documents'],
			meta: {
				requiresAuth: true,
				title: 'Documents',
				contractTypes: ['medical', 'dental'],
				rightsValue: 'forms'
			}
		},
		{
			path: '/document',
			name: 'DocumentsViewer',
			component: () => import('../views/DocumentsViewer.vue'),
			alias: ['/medical/document', '/dental/document'],
			meta: {
				requiresAuth: true,
				title: 'Document',
				contractTypes: ['medical', 'dental'],
				rightsValue: 'forms'
			}
		},
		{
			path: '/linking',
			name: 'DeepLink',
			component: () => import('../views/DeepLink.vue'),
			alias: ['/medical/linking', '/dental/linking', '/vision/linking'],
			meta: {
				requiresAuth: true,
				title: 'Linking',
				contractTypes: ['medical', 'dental', 'vision']
			}
		},
		{
			path: '/cost-estimates',
			name: 'DeepLinkEmbold',
			component: () => import('../views/DeepLinkEmbold.vue'),
			alias: ['/medical/cost-estimates'],
			meta: {
				requiresAuth: true,
				title: 'Estimate Your Costs',
				contractTypes: ['medical'],
				rightsValue: 'embold'
			}
		},
		{
			path: '/auth-success',
			name: 'AuthSuccess',
			meta: { hideFooter: true, hideChat: true }
		},
		// This must be the last item or items after it will result in a 404
		{
			path: '/:pathMatch(.*)*',
			name: 'NotFound',
			component: () => import('../views/NotFound.vue'),
			meta: { hideNavigation: true, hideFooter: true, hideChat: true }
		}
	],
	scrollBehavior() {
		return { top: 0 }
	}
})

const banList = router.options.routes.filter((route) => route.meta.hideChat)

router.beforeEach(async (to, from) => {
	const planStore = useSelectedPlan()
	const rightsStore = useAccessRights().accessRights
	const claimsFiltersStore = useClaimsFilters().loaded

	if (to.name === 'MedicalClaims' && !from.path.includes('claim') && from.name != undefined) {
		useClaimsFilters().$reset()
	}

	// Handle contractType Redirect
	function contractTypeRedirects() {
		const contractType = planStore?.contractType ? planStore.contractType.toLowerCase() : false

		// checks if planStore and to.meta contractTypes exist
		if (contractType && to.meta?.contractTypes) {
			// IF we need a contract type, check to make sure the url isn't already using it
			if (!to.path.includes(contractType) && to.meta.contractTypes.includes(contractType)) {
				to.path = `/${contractType}${to.path}`
				to.fullPath = `/${contractType}${to.fullPath}`
				return true
			}
		}

		return true
	}

	// Check route vs accessRights
	function routeAccessRights() {
		// IF route HAS meta.rightsValue test meta.rightsValue against accessRights
		if (to.meta.rightsValue) {
			// Check if user has rights to view programs
			if (to.meta.rightsValue === 'programs' && rightsStore.programs.length > 0) {
				return contractTypeRedirects()
			}
			// Check programs by ID
			else if (
				to.meta.rightsValue === 'programs-id' &&
				rightsStore.programs.find((x) => x.programId === to.params.id)
			) {
				return contractTypeRedirects()
			}
			// Check is user has payBill or medicarePayments
			else if (
				to.meta.rightsValue === 'payment' &&
				(rightsStore?.payBill ||
					(!rightsStore?.payBill &&
						rightsStore?.pageIdentifiers.some((x) => x.type == 'medicarePayment')))
			) {
				return contractTypeRedirects()
			}
			//embold redirect
			else if (
				to.meta.rightsValue === 'embold' &&
				rightsStore?.findCareAndCostOptions.filter((x) => x.type === 'embold').length > 0
			) {
				return contractTypeRedirects()
			}
			// Check is user have findCareAndCostOptions
			else if (
				to.meta.rightsValue === 'findCareAndCostOptions' &&
				(rightsStore.findCareAndCostOptions?.length > 0 || rightsStore?.findCare)
			) {
				return contractTypeRedirects()
			}
			// Check all other rights
			else if (rightsStore[to.meta.rightsValue]) {
				return contractTypeRedirects()
			}
			// IF no access 404
			else {
				return 'NotFound'
			}
		}
		// IF page doesn't need rights
		else {
			if (to.meta?.contractTypes) {
				if (to.meta?.contractTypes.includes(planStore.contractType.toLowerCase())) {
					return contractTypeRedirects()
				} else {
					return 'NotFound'
				}
			} else {
				return contractTypeRedirects()
			}
		}
	}

	// Before each route we test if the system availability is down and redirect to the outage-message
	checkSystemAvailable()

	if (to.meta?.title) {
		document.title = `${to.meta.title} - Blueprint Portal`
	} else if (to.meta?.pageTitle) {
		document.title = `${getContent(to.params.id).title} - Blueprint Portal`
	} else {
		document.title = `Blueprint Portal`
	}

	const docBody = document.querySelector('body')

	function removeChat() {
		if (document.querySelector('#five9LiveChatWidget'))
			document.querySelector('#five9LiveChatWidget').remove()
		if (document.querySelector('#five9OpenChatButton'))
			document.querySelector('#five9OpenChatButton').remove()
	}

	const removeListener = () => {
		if (docBody.classList.contains('chat_started')) {
			if (docBody.classList.contains('no_chat')) removeChat()
			docBody.classList.remove('chat_started')
		}
	}

	// Added to remove the widget from any page that is in ban list
	if (Object.values(banList).filter((subRoute) => subRoute.path === to.path).length > 0) {
		docBody.classList.add('no_chat')
		window.addEventListener('chatEnded', removeListener, { once: true })

		if (docBody.classList.contains('chat_started')) {
			Five9ChatTerminate()
		} else {
			removeChat()
		}
	} else {
		docBody.classList.remove('no_chat')
	}

	let token = sessionStorage.getItem('access_token')
	if (to.path == '/auth-success') {
		if (token == null || token == undefined || token == 'undefined') {
			await getToken(to.query.code)
			return { name: 'SelectPlan' }
		} else {
			return { name: 'SelectPlan' }
		}
	} else if (to.meta.requiresAuth) {
		if (token == null || token == undefined || token == 'undefined') {
			sessionStorage.setItem('deep_link', to.path)
			return { name: 'login' }
		} else if (!isTokenValid()) {
			await refreshAuthToken()
			return routeAccessRights()
		} else {
			return routeAccessRights()
		}
	} else {
		if (to.name === 'NotFound' && token != null && planStore?.loaded) {
			to.meta.hideNavigation = false
			to.meta.hideFooter = false
			to.meta.hideChat = false
		} else if (to.name === 'NotFound' && token != null) {
			to.meta.hideNavigation = false
		}

		return true
	}
})

const checkSystemAvailable = async () => {
	const res = await getSystemAvailability()
	if (!res.isSystemAvailable) {
		router.push({ name: 'outagemsg' })
	}
}

const getToken = async (code) => {
	await getOAuthToken(code).then(() => {
		const token = sessionStorage.getItem('access_token')
		return
	})
}

export default router
