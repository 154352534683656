<template>
	<div></div>
</template>

<script>
import { retrieveAccessRights } from '../composables/accessRights.comp.js'
import { useSelectedPlan } from '@/stores/plans/selectedPlan.js'
import { retrievePatientData } from '@/composables/patientData.comp.js'

export default {
	name: 'Five9ChatWidget',
	data() {
		return {
			options: {
				appId: 'abcbs',
				description: '5-10 Update',
				domain: 'abcbs',
				campaignName: '',
				subtitle: 'Blueprint Portal Chat',
				waitingImage: 'https://live-chat.ps.five9.com/waiting-blue.gif',
				waitingMessage: 'Please wait for the next available team member to assist you.',
				showEndButton: true,
				endConfirmation: true,
				launcherOpenText: 'Chat',
				titleBackgroundColor: '#015987',
				welcomeTextColor: '#2778bc',
				agentTextColor: '#2778bc',
				customerBubbleColor: '#2778bc',
				templateButtonColor: '#96cff0',
				templateButtonHoverColor: '#96cff0',
				buttonBackgroundColor: '#2778bc',
				miniSubmitColor: '#ffffff',
				miniSubmitBackgroundColor: '#2778bc',
				miniForm: true,
				miniSubmitButtonText: 'Begin Chat Session',
				formData: [
					{
						label: 'Name',
						cav: 'contact.name',
						formType: 'both',
						type: 'text',
						required: true
					},
					{
						label: 'Email',
						cav: 'contact.email',
						formType: 'both',
						type: 'email',
						required: true,
						value: '',
						readOnly: false
					},
					{
						label: 'Subject',
						cav: 'Subject',
						formType: 'email',
						type: 'text',
						required: true
					},
					{
						type: 'text',
						formType: 'both',
						required: true,
						readOnly: true,
						label: 'Member ID',
						cav: 'Salesforce.memberid'
					},
					{
						type: 'text',
						formType: 'both',
						required: true,
						readOnly: true,
						label: 'Date of Birth',
						cav: 'dateOfBirth'
					},
					{
						label: 'Question',
						cav: 'Question',
						formType: 'both',
						type: 'textarea',
						required: true
					}
				],
				markRequiredFields: true,
				blockRestart: true,
				disclaimer: 'Test',
				timezone: 'America/Chicago',
				useBusinessHours: true,
				newMessageFormat: true,
				fontChat: 'Roboto, serif',
				fontForm: 'Roboto, serif',
				fontFormInput: 'Roboto, serif',
				fontMenu: 'Roboto, serif',
				fontTitle: 'Roboto Condensed, serif',
				fontTemplate: 'Roboto, serif',
				styleSheets: [
					'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
					`${window.location.origin}/five9Styles.css`
				]
			},
			selectedPlan: null,
			test: {
				name: null,
				birthDate: null,
				telecom: null
			}
		}
	},
	mounted() {
		//Initiate process
		this.selectedPlan = useSelectedPlan()
		this.gainAccess()
	},
	methods: {
		chatCallback(event) {
			try {
				switch (event.type) {
					case 'accepted':
						console.log(`Chat has been accepted by ${event.agentDisplayName}`)
						document.querySelector('body').classList.add('chat_started')
						break
					case 'endClicked':
						console.log('End button was clicked')
						break
					case 'endConfirmation':
						console.log('End Confirmation should be displayed by consumer...')
						break
					case 'endChatConfirmed':
						console.log('End Chat Confirmed')
						break
					case 'endChatCancelled':
						console.log('End Chat Cancelled')
						break
					case 'terminate':
						window.dispatchEvent(chatEnded)
						document.querySelector('body').classList.remove('chat_started')
						console.log(`Chat terminated`)
						break
					default:
						break
				}
			} catch (exception) {
				console.error('Exception caught in event handler: ' + exception.stack)
			}
		},
		async patientInformation() {
			await retrievePatientData().then((res) => {
				setTimeout(() => {
					const nameIndex = this.options.formData.findIndex((el) => el.label === 'Name')
					const emailIndex = this.options.formData.findIndex((el) => el.label === 'Email')
					const dobIndex = this.options.formData.findIndex((el) => el.label === 'Date of Birth')
					const salesForceIndex = this.options.formData.findIndex(
						(el) => el.cav === 'Salesforce.memberid'
					)

					const { name, birthDate, telecom } = res.patientData[this.selectedPlan.memberId]

					const email = telecom.filter((tele) => tele.system === 'email')[0].value
					this.options.formData[nameIndex].value = name[0]['given'][0]
					this.options.formData[emailIndex].value = email
					this.options.formData[dobIndex].value = birthDate
					this.options.formData[salesForceIndex].value = this.selectedPlan.memberId
					return
				}, 10)
			})
		},
		async gainAccess() {
			//Get current access rights
			await retrieveAccessRights()
				.then(async (res) => {
					if (res.accessRights.chatType === 'Five9') {
						this.options.campaignName = res.accessRights.chatQueue
						await this.patientInformation()
						return true
					} else {
						return false
					}
				})
				.then((showChat) => {
					if (showChat) {
						this.options.callback = this.chatCallback
						Five9ChatPlugin(this.options)
					}
				})
		}
	}
}
</script>
