<template>
	<v-dialog>
		<v-card color="white" class="modal-container">
			<v-card-title class="mb-3 text-center"> Linking Disclaimer </v-card-title>
			<v-card-text class="text-secondary">
				<p>
					If you choose to access other websites from this website, you agree, as a condition of
					choosing any such link or access, that {{ lob }} is not and shall not be responsible or
					liable to you or to others in any way for your decision to link to such other websites.
					You further agree that {{ lobAbbr }} and its affiliates, its directors, officers,
					employees and agents ("the {{ lobAbbr }} Parties") are not responsible for the content of
					any other website to which you may link, nor are {{ lobAbbr }} or the
					{{ lobAbbr }} Parties liable or responsible under any circumstances for the activities,
					omissions or conduct of any owner or operator of any other website. Once you choose to
					link to another website, you understand and agree that you have exited this website and
					are no longer accessing or using any {{ lobAbbr }} Data. You understand and agree that by
					making any third-party website link available as an option to you, {{ lobAbbr }} does not
					in any way endorse any such website, nor state or imply that you should access such
					website or any services, products or information which may be offered to you through such
					other websites or by the owner or operator of such other websites. The owners or operators
					of any other websites (not {{ lobAbbr }}) are solely responsible for the content and
					operation of all such websites. {{ lobAbbr }} makes no warranties or representations of
					any kind, express or implied, nor of merchantability or fitness for a particular purpose,
					nor of non-infringement, with regard to the content or operation of any other website to
					which you may link from this website.
				</p>
			</v-card-text>
			<v-card-actions class="mt-4">
				<v-btn variant="flat" class="primary" text="Close" @click="$emit('closeDialog')"></v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		lob: String,
		lobAbbr: String
	}
}
</script>

<style lang="scss" scoped>
.modal-container {
	height: auto;
	width: 600px;
	margin: 0 auto;
	padding: 16px;
	border-radius: 12px !important;

	.info-icon {
		background: $primary-100;
		color: $primary-600;
		padding: 24px;
		border-radius: 50%;
	}
	:deep(.v-toolbar__prepend) {
		margin-inline-start: 0;
	}
	.v-card-text,
	.v-card-title,
	.v-card-actions {
		padding: 0 !important;
	}

	.v-btn {
		width: 100%;
		height: 44px;
	}

	.v-btn__content {
		color: #757575;
	}
}

@media only screen and (max-width: 600px) {
	.modal-container {
		width: 100%;
		max-width: 343px;
	}
}
</style>
