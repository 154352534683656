import enUsJson from './content-en-us.json'

function checkDate(date_logic) {
	if (!date_logic) return

	const today = new Date()
	const future = new Date(date_logic.date)
	return today - future >= 0 ? true : false
}

export const getContent = (contentId, language = 'en-us', fromCms = false) => {
	if (!fromCms) {
		if (language !== 'en-us') return
		const result = enUsJson.filter((json) => json.content_id === contentId)
		if (checkDate(result[0]?.date_logic)) {
			return result.length === 0 ? contentId : result[0]['data-june12']
		} else {
			return result.length === 0 ? contentId : result[0]['data']
		}
	}
	return contentId
}
