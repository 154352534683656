import { createApp, ref } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import VueGtag from 'vue-gtag'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify/lib/framework.mjs'
import { ArkansasBCBSLight } from './styles/theme'
import { getContent } from '@/services/cms/util.js'
import ErrorModal from '@/components/ErrorModal.vue'
import LoadingSpinnerTakeover from '@/components/LoadingSpinnerTakeover.vue'

const app = createApp(App)
const vuetify = createVuetify({
	components: {
		ErrorModal,
		LoadingSpinnerTakeover
	},
	theme: {
		defaultTheme: 'ArkansasBCBSLight',
		themes: {
			ArkansasBCBSLight
		}
	},
	icons: {
		defaultSet: 'mdi',
		aliases,
		sets: {
			mdi
		}
	}
})

app.config.globalProperties.getContent = getContent
const showLoadingTakeover = (app.config.globalProperties.$showLoadingTakeover = ref(false))
app.config.globalProperties.$LoadingTakeover = (val) => {
	showLoadingTakeover.value = val
}
const showError = (app.config.globalProperties.$showError = ref(false))
app.config.globalProperties.$Error = (val) => {
	showError.value = val
}

export const globals = app.config.globalProperties

const pinia = createPinia()
pinia.use(piniaPluginPersistedState)

app.use(pinia)
app.use(vuetify)
app.use(router)
if (import.meta.env.PROD) {
	app.use(
		VueGtag,
		{
			config: {
				id: 'G-RJNCZBHZSS'
			}
		},
		router
	)
} else {
	app.use(
		VueGtag,
		{
			config: {
				id: 'G-GMKTPT6X46'
			}
		},
		router
	)
}

router.isReady().then(() => {
	app.mount('#app')
})
